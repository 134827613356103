<template>
    <div class="block">
        <div class="flex justify-center w-full">
            <div v-for="(item, n) in data" :key="n">
                <b-button type="is-primary" @click="openUrl(item)">{{ item.Name }}</b-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array
        }
    },
    methods: {
        openUrl (e) {
            if (e.Url.includes('http')) window.open(e.Url, '_self')
            else this.$router.push(e.Url)
        }
    }
}
</script>
